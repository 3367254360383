<template>
    <div>
        <div v-if="success" class="d-flex justify-center flex-column mt-10">
            <v-icon large color="success">mdi-check-circle-outline</v-icon>
            <p class="dark--text text-center mt-2 font-weight-bold">
                Thank you, your submission was successful!
            </p>
        </div>
        <v-form v-else ref="form">
            <CustomInput
                label="Title*"
                v-model="nftoken.title"
                :rules="[(v) => !!v || 'Title is required']"
                required
                :error="errors.title"
                @change="errors.title = ''"
            />
            <CustomInput
                label="Description*"
                type="textarea"
                v-model="nftoken.description"
                :rules="[(v) => !!v || 'Description is required']"
                required
                :error="errors.description"
                @change="errors.description = ''"
            />

            <div class="d-flex flex-row align-center">
                <span class="font-weight-bold dark--text mr-2">
                    Is it on sale already?
                </span>
                <v-radio-group
                    v-model="nftoken.is_sale_on"
                    row
                    :error-messages="errors.is_sale_on"
                    @change="errors.is_sale_on = ''"
                >
                    <v-radio
                        color="secondary"
                        label="Yes"
                        :value="true"
                    ></v-radio>
                    <v-radio
                        color="secondary"
                        label="No"
                        :value="false"
                    ></v-radio>
                </v-radio-group>
            </div>
            <v-row>
                <v-col>
                    <CustomDateInput
                        label="Date of sale"
                        v-model="nftoken.sale_date"
                        :error="errors.is_sale_on"
                        @change="errors.is_sale_on = ''"
                    />
                </v-col>
                <v-col>
                    <PriceInput
                        v-model="nftoken.sale_price"
                        label="Sale price*"
                        @setCurrency="(v) => (nftoken.sale_currency = v)"
                        :currency="nftoken.sale_currency"
                        :rules="[(v) => !!v || 'Price is required']"
                        required
                        :error="errors.sale_price"
                        @change="errors.sale_price = ''"
                    />
                </v-col>
            </v-row>
            <v-row
                class="secondary--text text-caption files-form"
                :class="$vuetify.breakpoint"
                dense
            >
                <v-col cols="12" md="3" sm="6" xs="12">
                    <CustomFileUpload
                        label="Large image*"
                        v-model="nftoken.images[0]"
                        :error="nftoken.images[0].error"
                    />
                </v-col>
                <v-col cols="4" md="3" sm="6" xs="4">
                    <CustomFileUpload
                        label="Small image 1*"
                        v-model="nftoken.images[1]"
                        :error="nftoken.images[1].error"
                    />
                </v-col>
                <v-col cols="4" md="3" sm="6" xs="4">
                    <CustomFileUpload
                        label="Small image 2*"
                        v-model="nftoken.images[2]"
                        :error="nftoken.images[2].error"
                    />
                </v-col>
                <v-col cols="4" md="3" sm="6" xs="4">
                    <CustomFileUpload
                        label="Small image 3*"
                        v-model="nftoken.images[3]"
                        :error="nftoken.images[3].error"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <CustomInput
                        label="Total supply*"
                        type="number"
                        v-model="nftoken.total_supply"
                        :rules="[(v) => !!v || 'Total supply is required']"
                        required
                        :error="errors.total_supply"
                        @change="errors.total_supply = ''"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <CustomInput
                        label="Website*"
                        v-model="nftoken.website"
                        :rules="[(v) => !!v || 'Website is required']"
                        required
                        :error="errors.website"
                        @change="errors.website = ''"
                    />
                </v-col>
                <v-col>
                    <CustomInput
                        label="Twitter"
                        v-model="nftoken.twitter"
                        :error="errors.twitter"
                        @change="errors.twitter = ''"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <CustomInput
                        label="Discord"
                        v-model="nftoken.discord"
                        :error="errors.discord"
                        @change="errors.discord = ''"
                    />
                </v-col>
                <v-col>
                    <CustomInput
                        label="Opensea"
                        v-model="nftoken.opensea"
                        :error="errors.opensea"
                        @change="errors.opensea = ''"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <CustomInput
                        label="Email"
                        type="email"
                        v-model="nftoken.email"
                        :error="errors.email"
                        @change="errors.email = ''"
                        :rules="[rules.email]"
                    />
                </v-col>
            </v-row>
            <v-row class="info--text text-caption font-weight-thin">
                <v-col>*mandatory field</v-col>
            </v-row>
            <v-row justify="center">
                <div id="recaptcha-container"></div>
            </v-row>
            <v-row class="mt-5" justify="center">
                <div v-if="loading" class="d-flex align-center justify-center">
                    <v-progress-circular
                        :size="60"
                        :width="5"
                        color="purple"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <GradientButton v-else @click="handleSubmit">
                    Send submission
                </GradientButton>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
    recaptchaInit,
    recaptchaSend,
    recaptchaDestroy,
} from "../utils/recapthca";

export default {
    name: "NftokenSubmitForm",
    watch: {
        "nftoken.is_sale_on"(val) {
            if (val) {
                this.nftoken.type = "live";
            } else {
                this.nftoken.type = "mint";
            }
        },
    },
    computed: {
        ...mapGetters({
            loading: "nftoken/loading",
            success: "nftoken/success",
            nftoken: "nftoken/item",
            errors: "nftoken/errors",
            recaptchaToken: "recaptchaToken",
        }),
    },
    methods: {
        ...mapActions({
            setLoading: "nftoken/setLoading",
            storeNftoken: "nftoken/storeItem",
        }),
        async handleSubmit() {
            let valid = this.$refs.form.validate();

            this.nftoken.images.forEach((image) => {
                if (image.url == undefined) {
                    valid = false;
                    image.error = "image is required";
                }
            });

            if (valid) {
                this.setLoading(true);
                recaptchaInit(this.recaptchaToken)
                    .then(() => {
                        recaptchaSend(
                            this.recaptchaToken,
                            "submit_nft",
                            this.storeNftoken
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },

        beforeDestroy() {
            recaptchaDestroy();
        },
    },
    created() {
        this.rules = {
            email: (value) => {
                if (value) {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                }

                return true;
            },
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
